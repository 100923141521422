import http from "@/utils/HttpClient"
import { handleErrors } from "@/helpers/errors.helper"

/**
 * Сервис для работы с авторизацией
 */
export default {
	/**
	 * Получение директорий
	 */
	async getDirectories() {
		try {
			return await http.get("imagerizer/dir")
		} catch (errors) {
			throw handleErrors(errors, "Ошибка получения директорий")
		}
	},

	/**
	 * Создание задачи
	 */
	async createTask(data) {
		try {
			const headers = { "Content-Type": "multipart/form-data" }

			return await http.post("imagerizer/task", data, headers)
		} catch (errors) {
			throw handleErrors(errors, "Ошибка создания задачи")
		}
	},

	/**
	 * Создание задачи
	 */
	async uploadArchive({ data, headers, options }) {
		try {
			return await http.upload("imagerizer/archive", data, headers, options)
		} catch (errors) {
			throw handleErrors(errors, "Ошибка загрузки архива")
		}
	},

	/**
	 * Создание задачи
	 */
	async getTasks() {
		try {
			return await http.get("imagerizer/task")
		} catch (errors) {
			throw handleErrors(errors, "Ошибка получение списка задач")
		}
	},

	/**
	 * Создание задачи
	 */
	async deleteTask({ id }) {
		try {
			return await http.delete("imagerizer/task", { id })
		} catch (errors) {
			throw handleErrors(errors, "Ошибка удаления задачи")
		}
	},

	/**
	 * Получить файл со картинками
	 * @param {Object} data
	 */
	async downloadArchive({ data, headers, options }) {
		const filename = `${data.filename}.zip`

		try {
			return await http.download(`imagerizer/download/archive/${data.id}`, filename, headers, {}, { method: "get", ...options })
		} catch (errors) {
			throw handleErrors(errors, "Ошибка скачивания архива")
		}
	},

	/**
	 * Создать задачу на скачивания архива
	 * @param {Object} data
	 */
	async createTaskDownloadArchive({ id }) {
		try {
			return await http.get(`imagerizer/download/create-task/${id}`)
		} catch (errors) {
			throw handleErrors(errors, "Ошибка при подготовке к скачиванию")
		}
	}
}
