<template>
	<div>
		<b-table
			id="directories-table"
			:hover="false"
			responsive
			:items="normalizedTasks"
			:fields="taskFields"
			caption-top
			show-empty
			empty-text="Задач нет"
			:per-page="perPage"
			:current-page="currentPageDirectories"
			:dark="true"
			@row-clicked="onClickTask"
		>
			<template #table-caption>
				<div class="caption">
					<p class="table-header">Список задач</p>
					<div>
						<b-button size="sm" variant="primary" @click="onReload()" class="mr-2">
							Обновить список
						</b-button>
						<b-button size="sm" variant="success" @click="onShowModal('upload-archive')" class="mr-2">
							Загрузить архив
						</b-button>
						<b-button size="sm" variant="success" @click="onShowModal('new-task-modal')" class="mr-2">
							Загрузить файл
						</b-button>
					</div>
				</div>
			</template>

			<template #cell(status)="row">
				<b-badge href="#" :variant="row.item.statusColor">{{ row.item.status }}</b-badge>
			</template>

			<template #cell(actions)="row">
				<div v-if="row.item.isUnload">
					<b-button size="sm" variant="primary" @click.once="onDownloadArchive(row)" class="mr-2 mb-2">
						Выгрузить
					</b-button>
				</div>
				<div v-else>
					<b-button
						v-if="row.item.statusId == 3 && !row.item.preparingUnloading"
						size="sm"
						variant="primary"
						@click.prevent="onCreateTaskDownloadArchive(row)"
						class="mr-2 mb-2"
					>
						Подготовить выгрузку
					</b-button>
					<b-button v-else size="sm" variant="primary" disabled class="mr-2 mb-2">
						Подготовить выгрузку
					</b-button>
				</div>
				<div>
					<b-button size="sm" variant="danger" @click.prevent="onShowModal('delete-modal'), (currentRow = row)" class="mr-2 mb-2">
						Удалить
					</b-button>
				</div>
			</template>

			<template #custom-foot>
				<b-tr>
					<b-th colspan="8">
						<b-pagination
							v-if="normalizedTasks.length"
							v-model="currentPageDirectories"
							:total-rows="normalizedTasks.length"
							:per-page="perPage"
							aria-controls="directories-table"
							align="right"
							class="mb-0"
						></b-pagination>
					</b-th>
				</b-tr>
			</template>
		</b-table>

		<div>
			<b-modal id="upload-archive" title="Загрузка архива">
				<div>
					<b-col class="sm-12">
						<b-row class="mb-3">
							<b-form-file
								v-model="archive"
								size="sm"
								placeholder="Выберите архив картинок"
								browse-text="Выбрать"
								accept="application/zip"
							></b-form-file>
						</b-row>
						<b-row>
							<b-form-file v-model="file" size="sm" placeholder="Выберите файл excel" browse-text="Выбрать"></b-form-file>
						</b-row>
					</b-col>
				</div>
				<template #modal-footer>
					<div class="text-center w-100 new-task-modal__buttons">
						<b-button variant="success" @click.prevent="onUploadArchive()">Загрузить</b-button>
						<b-button variant="danger" @click="onHideModal('upload-archive')">Закрыть</b-button>
					</div>
					<b-progress v-if="showProgress" :value="uploadPercentage" :max="100" show-progress animated class="w-100"></b-progress>
				</template>
			</b-modal>
		</div>

		<div>
			<b-modal id="new-task-modal" title="Загрузка файла">
				<div>
					<b-col class="sm-12">
						<b-row class="mb-3">
							<b-form-select v-model="selectedDir" :options="normalizedDirectories"></b-form-select>
						</b-row>
						<b-row>
							<b-form-file v-model="file" size="sm" placeholder="Выберите файл" browse-text="Выбрать"></b-form-file>
						</b-row>
					</b-col>
				</div>
				<template #modal-footer>
					<div class="text-center w-100 new-task-modal__buttons">
						<b-button variant="success" @click.prevent="onUploadFile()">Загрузить</b-button>
						<b-button variant="danger" @click="onHideModal('new-task-modal')">Закрыть</b-button>
					</div>
				</template>
			</b-modal>
		</div>

		<div>
			<b-modal
				id="task-modal"
				:title="`Детальная инф. «${task.name}»`"
				scrollable
				class="h-100"
				header-bg-variant="dark"
				header-border-variant="Secondary"
				header-text-variant="light"
				body-bg-variant="dark"
				body-text-variant="light"
				footer-bg-variant="dark"
				footer-border-variant="Secondary"
			>
				<div class="d-block">
					<b-list-group>
						<b-list-group-item class="d-flex justify-content-between align-items-center" variant="dark">
							Кол-во обработанных фото:
							<b-badge variant="primary" pill>{{ task.result.success + task.result.fail }}</b-badge>
						</b-list-group-item>

						<b-list-group-item class="d-flex justify-content-between align-items-center" variant="dark">
							Успешно обработанно:
							<b-badge variant="success" pill>{{ task.result.success }}</b-badge>
						</b-list-group-item>

						<b-list-group-item class="d-flex justify-content-between align-items-center" variant="dark">
							Не успешно обработанно:
							<b-badge variant="danger" pill>{{ task.result.fail }}</b-badge>
						</b-list-group-item>

						<label v-if="task.result.errors.length" class="label-error">Список ошибок</label>
						<b-list-group-item
							v-for="error in task.result.errors"
							:key="error.error"
							variant="danger"
							class="d-flex justify-content-between align-items-center"
						>
							{{ error.error }}
						</b-list-group-item>
					</b-list-group>
				</div>
				<template #modal-footer>
					<div class="text-center w-100 new-task-modal__buttons">
						<b-button variant="outline-primary" block @click="onHideModal('task-modal')">Закрыть</b-button>
					</div>
				</template>
			</b-modal>
		</div>

		<div>
			<b-modal
				id="delete-modal"
				title="Удаление задачи"
				@ok="deleteOk()"
				@cancel="deleteCancel()"
				ok-variant="danger"
				ok-title="Удалить"
				cancel-variant="primary"
				cancel-title="Отмена"
			>
				<template #default>
					<div>
						Вы уверены, что хотите удалить задачу
						<b>«{{ currentRow ? currentRow.item.name : "" }}»</b>
						? Данное действие не обратимо! Также удалятся все загруженные фотографии.
					</div>
				</template>
			</b-modal>
		</div>

		<div class="alert">
			<b-alert variant="danger" class="mb-0" :show="isShowError">{{ error }}</b-alert>
			<b-alert variant="success" class="mb-0" :show="isShowSuccess">{{ success }}</b-alert>
			<b-alert variant="success" class="mb-0" :show="downloadProgress">
				Выгрузка архива {{ downloadArchiveName }}
				<b-progress v-if="downloadProgress" :value="downloadPercentage" :max="100" show-progress animated class="w-100"></b-progress>
			</b-alert>
		</div>
	</div>
</template>

<script>
import DirectoryService from "@/services/DirectoryService"

export default {
	data: () => ({
		directories: [],
		selectedDir: null,
		tasks: [],
		task: {
			name: null,
			result: {
				success: 0,
				fail: 0,
				errors: []
			}
		},
		currentRow: null,
		taskFields: [
			{
				key: "id",
				label: "ID"
			},
			{
				key: "name",
				label: "Название"
			},
			{
				key: "directoryName",
				label: "Название папки"
			},
			{
				key: "dt_create",
				label: "Дата создания"
			},
			{
				key: "dt_start",
				label: "Дата запуска"
			},
			{
				key: "dt_finish",
				label: "Дата окончания"
			},
			{
				key: "status",
				label: "Статус"
			},
			{
				key: "actions",
				label: "Действия"
			}
		],
		archive: null,
		file: null,

		statuses: {
			0: {
				name: "Новая",
				color: "primary"
			},
			1: {
				name: "Забронирована",
				color: "secondary"
			},
			2: {
				name: "В работе",
				color: "info"
			},
			3: {
				name: "Завершена",
				color: "success"
			},
			4: {
				name: "Ошибка",
				color: "danger"
			},
			5: {
				name: "В ожидании",
				color: "warning"
			}
		},

		perPage: 15,
		currentPageDirectories: 1,
		currentPageTasks: 1,

		success: null,
		error: null,
		isShowError: false,
		isShowSuccess: false,
		showProgress: false,
		uploadPercentage: 0,
		downloadProgress: false,
		downloadPercentage: 0,
		downloadArchiveName: ""
	}),

	computed: {
		normalizedDirectories() {
			let directories = this.directories.map(directory => {
				return {
					value: directory,
					text: directory
				}
			})

			directories.unshift({ value: null, text: "-- Выберите папку --" })

			return directories
		},

		normalizedTasks() {
			return this.tasks.map(task => {
				return {
					...task,
					status: this.statuses[task.status].name,
					statusColor: this.statuses[task.status].color,
					dt_create: this.formatedDate(task.dt_create),
					dt_start: this.formatedDate(task.dt_start),
					dt_finish: this.formatedDate(task.dt_finish),
					directoryName: this.getDirectoryName(task.input.dir),
					isUnload: Boolean(task.input.archive),
					statusId: task.status,
					preparingUnloading: task.input.preparing_unloading
				}
			})
		}
	},

	async created() {
		try {
			this.directories = await DirectoryService.getDirectories()
			this.tasks = await DirectoryService.getTasks()

			setInterval(async () => {
				this.tasks = await DirectoryService.getTasks()
			}, 15000)
		} catch (error) {
			this.showError(error)
		}
	},

	methods: {
		async onUploadArchive() {
			try {
				if (!this.archive) {
					throw "Выберите zip архив"
				}

				if (!this.file) {
					throw "Выберите excel файл"
				}

				await DirectoryService.uploadArchive({
					data: { archive: this.archive, file: this.file },
					headers: {},
					options: {
						axiosApi: {
							onUploadProgress: function(progressEvent) {
								this.showProgress = true
								this.uploadPercentage = Math.round(Math.round((progressEvent.loaded / progressEvent.total) * 100), 2)
							}.bind(this)
						}
					}
				})

				this.onHideModal("upload-archive")
				this.showSuccess("Архив успешно загружен")
				this.tasks = await DirectoryService.getTasks()
			} catch (error) {
				this.onHideModal("upload-archive")
				this.showError(error)
			}
		},

		async onUploadFile() {
			try {
				if (!this.selectedDir) {
					throw "Выберите папку"
				}

				if (!this.file) {
					throw "Выберите файл"
				}

				const formData = new FormData()
				formData.append("file", this.file)
				formData.append("dir", this.selectedDir)
				await DirectoryService.createTask(formData)

				this.onHideModal("new-task-modal")
				this.showSuccess("Файл успешно загружен")
				this.tasks = await DirectoryService.getTasks()
			} catch (error) {
				this.showError(error)
			}
		},

		onClickTask(row) {
			this.task.name = row.name

			if (row.result) {
				this.task.result = row.result
			} else {
				this.task.result = {
					success: 0,
					fail: 0,
					errors: []
				}
			}

			this.$bvModal.show("task-modal")
		},

		async onReload() {
			try {
				this.directories = await DirectoryService.getDirectories()
				this.tasks = await DirectoryService.getTasks()
			} catch (error) {
				this.showError(error)
				throw error
			}
		},

		async onCreateTaskDownloadArchive(row) {
			try {
				await DirectoryService.createTaskDownloadArchive({
					id: row.item.id
				})
				this.tasks = await DirectoryService.getTasks()
			} catch (error) {
				this.showError(error)
				throw error
			}
		},

		async onDownloadArchive(row) {
			try {
				this.downloadArchiveName = row.item.directoryName
				await DirectoryService.downloadArchive({
					data: { id: row.item.id, filename: `Фотоотчет ${row.item.directoryName}` },
					headers: {},
					options: {
						axiosApi: {
							onDownloadProgress: function(progressEvent) {
								this.downloadProgress = true
								this.downloadPercentage = Math.round(Math.round((progressEvent.loaded / progressEvent.total) * 100), 2)

								if (this.downloadPercentage === 100) {
									this.downloadProgress = false
									this.downloadPercentage = 0
									this.downloadArchiveName = ""
								}
							}.bind(this)
						}
					}
				})

				this.tasks = await DirectoryService.getTasks()
			} catch (error) {
				this.showError(error)
				this.downloadProgress = false
				this.downloadPercentage = 0
				this.downloadArchiveName = ""
				throw error
			}
		},

		async deleteOk() {
			try {
				await DirectoryService.deleteTask({ id: this.currentRow.item.id })
			} catch (error) {
				this.showError(error)
				throw error
			}

			this.currentRow = null
			this.onReload()
			this.showSuccess("Задача успешно удалена")
		},

		deleteCancel() {
			this.currentRow = null
			this.onHideModal("delete-modal")
		},

		onShowModal(id) {
			this.$bvModal.show(id)
		},

		onHideModal(id) {
			this.$bvModal.hide(id)
			this.file = null
			this.archive = null
			this.selectedDir = null
			this.showProgress = false
			this.uploadPercentage = 0
		},

		getDirectoryName(path) {
			if (path) {
				const directoryName = path.split("/")

				return directoryName.pop()
			}

			return null
		},

		formatedDate(timestamp) {
			return timestamp ? new Date(timestamp * 1000).toLocaleDateString() : null
		},

		showError(error) {
			this.error = error
			this.isShowError = !this.isShowError
			this.showProgress = false
			this.uploadPercentage = 0
			setTimeout(() => {
				this.isShowError = !this.isShowError
				this.error = null
			}, 4000)
		},

		showSuccess(success) {
			this.success = success
			this.isShowSuccess = !this.isShowSuccess
			this.showProgress = false
			this.uploadPercentage = 0
			setTimeout(() => {
				this.isShowSuccess = !this.isShowSuccess
				this.success = null
			}, 4000)
		}
	}
}
</script>

<style lang="scss" scoped>
.caption {
	display: flex;
	justify-content: space-between;
}
.table-header {
	padding: 0 10px;
	margin: 0;
}

.new-task-modal {
	&__buttons {
		display: flex;
		justify-content: space-between;
	}
}

.alert {
	min-width: 280px;
	position: fixed;
	bottom: 20px;
	left: 20px;
	z-index: 10;
}

.label-error {
	margin-top: 10px;
}

/deep/.task-modal {
	height: 600px;
}

/deep/.modal-header {
	word-break: break-all;
}
</style>
